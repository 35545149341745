.Drawer {
  position: fixed;
  overflow: scroll;
  z-index: 1;

  .headers {
    color: black;
  }

  .sticky {
    position: sticky;
    background-color: #fff;
    top: -1px;
    z-index: 3;

    &.isSticking {
      box-shadow: 0px 3px 8px 1px #555;
    }
  }

  h2,
  h4 {
    font-family: "Acme", sans-serif;
  }

  .buildSectionNavigation {
    img {
      cursor: pointer;
      width: 50px;

      &.disable {
        opacity: 0.35;
        cursor: not-allowed;
      }
    }
  }
}
