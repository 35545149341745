$md: 769px !default;

#Stepper {
  padding-top: 0px !important;

  .MuiStepIcon-active {
    color: inherit !important;
  }
  .MuiStepIcon-completed {
    color: rgba(0, 0, 0, 0.38) !important;
  }
  .MuiStepIcon-root:hover {
    color: inherit !important;
    cursor: pointer !important;
  }

  // removes number in center of circle
  .MuiStepIcon-text {
    display: none;
  }

  position: relative;
  z-index: 2;

  .completedStep .MuiStepLabel-label {
    color: #999;
  }

  .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
    margin-top: 7px;
  }

  .MuiTypography-body2 {
    font-family: "Acme", sans-serif;
  }
}

.info-message-text {
  color: grey;
  font-style: italic;
  font-size: 14px;
  margin-bottom: 0.5rem;
}
