$md: 769px !default;

.Widgets {
  position: absolute;
  z-index: 1300;

  .toggleSizeButton {
    cursor: pointer;

    img {
      width: 24px;
    }
  }

  // customer support
  .supportCard {
    width: 335px;
    max-height: 75vh;
    position: fixed;
    top: 65px;
    left: 25px;
    background: white;
    border-radius: 8px;
    box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.25);
    z-index: 2;
    border-radius: 8px;
    overflow-x: hidden;
    overflow-y: auto;

    @media screen and (min-width: $md) {
      left: 370px;
      max-height: 85vh;
    }

    .submitButton {
      background-color: rgba(5, 79, 174, 0.7);
    }

    h4 {
      font-size: 22px;
    }

    .copyURL {
      .linkContainer {
        background: #eeeeee;
        border-radius: 6px;
        height: 40px;
        color: #686868;

        p {
          font-size: 14px;
        }

        button {
          color: #686868;
          background-color: white;
          border: none;
          border-radius: 6px;
          height: 24px;
          font-size: 12px;
          margin-right: 5px;
          box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.25);
        }
      }
    }

    .topSection {
      height: 120px;
      color: white;
      border-radius: 8px 8px 0px 0px;
      background: rgb(93, 188, 210);
      background: linear-gradient(45deg, rgba(93, 188, 210, 1) 0%, rgba(5, 79, 174, 0.7) 100%);

      p {
        font-size: 15px;
      }
    }

    .bottomSection {
      background: white;
      padding: 15px 0px;

      .form-group {
        button {
          display: -webkit-box;
        }

        .asterisk {
          color: #ff0000;
        }

        .helperText {
          font-size: 11px;
          color: red;
          font-style: italic;
        }

        .form-control,
        .custom-select {
          background-color: white;
          border-radius: 8px;
          box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.25);
        }
      }

      .submitNote {
        text-align: center;
        padding-bottom: 30px;

        img {
          width: 70%;
        }
      }
    }
  }
}


.tooltip {
  z-index: 1300 !important;
}