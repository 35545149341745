$md: 769px !default;
$lightTheme: #fafafa !default;

.card {
  cursor: pointer;
  box-shadow: 0 2px 7px 1px #8c8c8c;
  color: $lightTheme;
  border: none !important;
  margin-top: 5%;

  @media screen and (min-width: $md) {
    width: 16rem;
  }

  &.active {
    border: 2px dashed black !important;
  }

  &.construction {
    box-shadow: 1px 1px 2px 0px #999999 !important;
  }

  &:hover {
    @media (min-width: $md) and (orientation: landscape) {
      box-shadow: 1px 1px 2px 0px #999999;
    }
  }

  &.componentCard {
    background: linear-gradient(180deg, rgba(249, 249, 249, 1) 0%, rgba(236, 236, 236, 1) 50%);

    @media screen and (min-width: $md) {
      width: 9rem;
      min-height: 155px;
    }

    .thumbnail {
      width: 100%;
    }

    .label {
      font-size: 0.8em;
      font-weight: 500;
      color: black;
      text-align: center;
    }
  }

  .frame {
    height: 120px;
    width: 100%;
    background-color: $lightTheme;
    position: relative;
    border-radius: 0.2rem 0.2rem 0rem 0rem;
    box-shadow: 0 4px 6px -6px #000000;

    @media screen and (min-width: $md) {
      height: 162px;
    }
  }

  .constructionImg {
    width: 70%;
    padding: 10px;
  }
  .playGroundImage {
    width: 95%;
    object-fit: contain;
  }

  .checkMark {
    position: absolute;
    background-color: white;
    border-radius: 50%;
    left: -15px;
    top: -15px;
    width: 35px;
    // mobile
    @media screen and (max-width: $md) {
      left: -12px;
      top: -10px;
      width: 25px;
    }
  }

  .card-title {
    font-size: 1.4em;
  }

  .card-text {
    font-size: 0.8em;
  }

  .disable {
    opacity: 35%;
  }

  button {
    background-color: white;
    border: none;
    border-radius: 2px;
    font-size: 0.7em;
    font-weight: bold;
  }

  .buttons-container {
    display: flex;
    justify-content: space-evenly;
    gap: 2px;
    align-items: center;
    width: 100%;
    margin-bottom: 12px;
  }

  .customize-button {
    background: #ffffff;
    border-radius: 10px;
    height: 29px;
    margin-top: 16px;
    box-shadow: 3px 3px 6px rgba(33,33,33,0.4);
    color: #111111 !important;
    font-weight: 500;
    text-transform: uppercase;
    height: 30px;
    &.icon {
      width: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        height: 18px;
      }
    }
  }

  &.orange {
    background-color: rgba(246, 123, 61, 0.5);
    button {
      color: rgba(246, 123, 61, 0.5);
    }

    &.active {
      box-shadow: 0px 1px 10px 3px rgba(246, 123, 61, 0.5);
    }
  }

  &.gray {
    background-color: rgba(109, 109, 109, 0.5);
    button {
      color: rgba(109, 109, 109, 0.5);
    }

    &.active {
      box-shadow: 0px 1px 10px 3px rgba(109, 109, 109, 0.5);
    }
  }

  &.blue {
    background-color: rgba(5, 79, 174, 0.7);
    button {
      color: rgba(5, 79, 174, 0.7);
    }

    &.active {
      box-shadow: 0px 1px 10px 3px rgba(5, 79, 174, 0.7);
    }
  }

  &.green {
    background-color: rgba(128, 162, 108, 0.5);
    button {
      color: rgba(128, 162, 108, 0.5);
    }

    &.active {
      box-shadow: 0px 1px 10px 3px rgba(128, 162, 108, 0.5);
    }
  }

  &.pink {
    background-color: rgba(233, 118, 152, 1);
    button {
      color: rgba(233, 118, 152, 1);
    }

    &.active {
      box-shadow: 0px 1px 10px 3px rgba(233, 118, 152, 1);
    }
  }

  &.purple {
    background-color: rgba(183, 142, 224, 1);
    button {
      color: rgba(183, 142, 224, 1);
    }

    &.active {
      box-shadow: 0px 1px 10px 3px rgba(183, 142, 224, 1);
    }
  }
}
