.Details {
  // icons
  img {
    height: 30px;
  }

  p {
    font-size: 0.85em;
  }

  .specs {
    .title {
      font-size: 11px;
      color: #828080;
    }

    .size {
      font-weight: bold;
    }
  }
}
