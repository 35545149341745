.react-joyride__tooltip {
  font-family: "Lato", sans-serif;

  p {
    font-size: 1.1em;
  }

  button {
    outline: none;
  }
}

.ProductTour {
  #pt_rp_logo {
    max-height: 75px;
  }

  .stepperImg {
    width: 70%;
  }

  b {
    color: #000;
  }
}
