$md: 769px !default;

.BuilderUI {
  height: 100%;
  width: 100%;
  background-color: white;
  position: relative;
  overflow: hidden;
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-out;

  .productTourHighlightNavigateSteps {
    position: absolute;
    width: 100%;
    height: 100px;
    top: 100px;
  }

  canvas {
    &:focus {
      outline: none;
    }
  }

  // this is adjusted dynamically in EnvironmentController.js
  .scene-canvas-cover {
    background: linear-gradient(0deg, rgb(130, 130, 130) 0%, rgb(130, 130, 130) 25%, rgb(80, 174, 229) 100%);
  }
}
