.lockScreen {
  font-family: "Lato", sans-serif;
  font-weight: bold;
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: white;
  // transform: translate(-50%, -50%) rotate(-90deg);
  z-index: 1000000;
  img {
    max-width: 100px;
  }
  h6 {
    text-align: center;
    padding: 50px 50px;
    max-width: 500px;
  }
}
