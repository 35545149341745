$md: 769px !default;

.sceneLoadingScreen {
  @media (max-width: $md) and (orientation: portrait) {
    box-shadow: 0 0 50px rgba(0, 0, 255, 0.15) inset;
  }
  box-shadow: 0 0 200px rgba(0, 0, 255, 0.15) inset;

  img {
    @media (max-width: $md) and (orientation: portrait) {
      width: 100%;
    }
    width: 50%;
  }
}
