$primary: #5b92f6;

$md: 769px !default;

html {
  height: 100vh;
  height: -webkit-fill-available;
}
body {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  height: 100vh;
  height: -webkit-fill-available;
  width: 100vw;
  overflow: hidden;
}

#react-root {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.LeftComponentDrawer {
  position: fixed;
  z-index: 1201;
  overflow-y: scroll;

  .component-button {
    border-radius: 7px;
    font-family: "Acme", sans-serif;
    border: 2px solid #005f9c;

    img {
      width: 25px;
      height: 25px;
    }
  }

  .headerButtons {
    font-size: 11px;
    font-family: "Acme", sans-serif;
    background: inherit;
    border: 1px solid black;
    border-radius: 3px;

    &:hover {
      @media (min-width: $md) and (orientation: landscape) {
        box-shadow: 1px 1px 2px 0px #999999;
      }
    }
  }

  .inactive-component {
    background-color: white;
    color: #054fae;
  }

  .active-component {
    color: white;
    background-color: #054fae;
    border: none;
  }
}

@import "../../node_modules/bootstrap/scss/bootstrap";

.btn {
  font-weight: bold;
}

.btn-primary {
  color: #ffffff;

  &:hover {
    color: #ffff;
  }
}
