$md: 769px !default;

.OverlayBadBrowsers {
  .OverlayBadBrowsersContainer {
    h6 {
      text-align: center;
    }

    // mobile
    @media (max-width: $md) {
      width: 80%;
      img {
        width: 50%;
      }
    }

    // desktop
    @media (min-width: $md) {
      width: 50%;
      img {
        width: 25%;
      }
    }
  }
}
