.grabSceneIndicator {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 45%;
  z-index: 1;
  pointer-events: none;
}

// if you want to see the css animation code go to the skateboards.com project
